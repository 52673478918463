/* You can add global styles to this file, and also import other style files */
:root {
  --bs-primary: #409568;
  --bs-primary-rgb: 64, 149, 104;
  --bs-blue: green;
  --bs-green: #409568;
  --bs-white: #ffffff;
  --bs-hover: #dcdbdd;
}

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @import '~animate.css/animate.min';
// @font-face {
//   font-family: 'Poppins';
//   src: url('./assets/Poppins-Regular.ttf');
// }
// *{
//   box-sizing: border-box;
// }
body {
  position: relative;
  font-family: "Poppins", sans-serif;
  background-color: #f4f7f5;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

input {
  height: 48px;
}

::ng-deep {
  .ant-modal-wrap {
    display: flex;
    align-items: center;

    .ant-modal {
      top: unset !important;
    }
  }
}

.custom-label {
  position: relative;

  input {
    transition: all 0.2s;
    touch-action: manipulation;
    cursor: text;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid gray;
    width: 300px;
    height: 50px;
    padding: 0 10px;
    @media screen and (max-width: 546px) {
      height: 35px;
      border-radius: 10px;
    }
  }

  textarea {
    transition: all 0.2s;
    touch-action: manipulation;
    cursor: text;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid gray;
    width: 300px;
    // height: 50px;
    padding: 0 10px;
    resize: none;

    &::placeholder {
      padding-top: 4px;
    }
  }

  .ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: table;
    width: 100% !important;
    border-collapse: separate;
    border-spacing: 0;
    background: #ffffff;
    border: 1px solid #dcdbdd;
    border-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .has-error {
    border: 1px solid #bb0728 !important;
  }

  .ant-select-selector {
    transition: all 0.2s;
    touch-action: manipulation;
    cursor: text;
    background-color: transparent;
    border-radius: 10px;
    border: 2px solid gray;
    width: 300px;
    height: 50px;
    width: 100% !important;
    z-index: 1000 !important;
    background: #ffffff;
    border: 1px solid #dcdbdd;
    border-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    @media screen and (max-width: 546px) {
      height: 35px;
      border-radius: 10px !important;
    }
  }

  .ant-select-selection-item {
    background-color: rgb(64, 149, 104);
    color: #ffffff;

    .anticon {
      color: #ffffff;
    }
  }

  label {
    position: absolute;
    top: 19px;
    left: 15px;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #84818a;
    background-color: #ffffff;
    transition: all 0.2s;
    z-index: 999 !important;
    /*enable click everywhere inside input */
    pointer-events: none;
  }

  .ant-select-selector::placeholder,
  .ant-select::placeholder,
  textarea::placeholder,
  input::placeholder {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #84818a;
  }

  /*make the placeholder disappear on focus*/
  textarea:focus::placeholder,
  input:focus::placeholder,
  .ant-select:focus::placeholder,
  .ant-select-selector:focus::placeholder {
    opacity: 0;
    color: gray;
  }

  textarea:focus,
  .ant-select-selector:focus,
  input:focus {
    outline: none;
    box-shadow: none;
  }

  textarea:placeholder-shown {
    padding-top: 10px;
  }

  textarea:placeholder-shown + label,
  .ant-select:placeholder-shown + label,
  .ant-select-selector:placeholder-shown + label,
  input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -ms-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    -webkit-transform: translate(0, 0) scale(1.5);
    -moz-transform: translate(0, 0) scale(1.5);
    -ms-transform: translate(0, 0) scale(1.5);
    -o-transform: translate(0, 0) scale(1.5);
    opacity: 0;
  }

  .ant-select-selector:focus + label,
  .ant-select-selector:not(:placeholder-shown) + label,
  textarea:focus + label,
  input:focus + label,
  textarea:not(:placeholder-shown) + label,
  input:not(:placeholder-shown) + label {
    -webkit-transform: translate(-2px, -30px) scale(1);
    -moz-transform: translate(2px, -30px) scale(1);
    -o-transform: translate(2px, -30px) scale(1);
    -ms-transform: translate(2px, -30px) scale(1);
    padding: 0 3px;
    opacity: 1;
    z-index: 999;
  }

  .ant-select:focus + label::after,
  .ant-select + label::after,
  textarea:focus + label::after,
  input:focus + label::after,
  input + label::after {
    content: " ";
    display: block;
    position: absolute;
    background: #ffffff;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    /* identical to box height, or 142% */
    color: #84818a;
    height: 3px;
    top: 50%;
    left: -0.2em;
    right: -0.2em;
    z-index: -1;
  }

  .ant-select-selector:not(:placeholder-shown) + label::after,
  textarea:not(:placeholder-shown) + label::after,
  input:not(:placeholder-shown) + label::after {
    left: -0.1em;
    right: -0.1em;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}

::ng-deep {
  .ant-checkbox-inner {
    border: 1px solid #a6a8a7;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #409568;
    border-color: #409568;
  }

  .ant-btn:hover,
  .ant-btn:focus {
    color: #409568;
    border-color: #409568;
  }
}

.center-modal-wrapper {
  display: flex;
  align-items: center;

  .center-modal {
    top: unset !important;
    padding-bottom: unset !important;
    // max-width: calc(750px - 30px) !important;
  }
}

// button hover
.button_hover:hover {
  transition: all 0.3s ease-in-out;
  background: #ffffff !important;
  color: #202020 !important;
  cursor: pointer;
}

.button_transparent:hover {
  transition: all 0.3s ease-in-out;
  background: #ffffff !important;
  color: #409568 !important;
  cursor: pointer;
  border: 1px solid #409568 !important;
}

.ant-spin-dot-item {
  background-color: #409568 !important;
}

.ant-spin-text {
  color: #409568;
}

nz-breadcrumb {
  cursor: pointer;
}

// ANimation rounde
.main {
  .circle1 {
    width: 704px;
    height: 704px;
    border-radius: 50%;
    border: 1px solid rgba(32, 180, 134, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .circle2 {
      width: 584.97px;
      height: 584.97px;
      border-radius: 50%;
      border: 1px solid rgba(32, 180, 134, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;

      .circle3 {
        width: 450.63px;
        height: 450.63px;
        border-radius: 50%;
        border: 1px solid rgba(32, 180, 134, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .circle4 {
          width: 275.48px;
          height: 275.48px;
          border-radius: 50%;
          border: 1px solid rgba(32, 180, 134, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }
}

.square {
  width: 790px;
  height: 790px;
  background: #409568;
  opacity: 0.15;
  filter: blur(137px);

  @media only screen and (max-width: 576px) {
    background: transparent;
    opacity: 0;
    filter: blur(0px);
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.common_btn {
  color: #fff !important;
  background-color: var(--bs-primary) !important;
  border: 1px solid var(--bs-primary) !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  transition: all 0.3s;
  img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(56%) saturate(428%)
      hue-rotate(69deg) brightness(92%) contrast(87%);
  }
  span {
    color: #c3ded0;
  }

  svg,
  svg path {
    transition: all 0.2s;
    fill: #fff;
  }

  &:disabled {
    opacity: 0.8 !important;
    background-color: #ececec !important;
    color: #a6a8a7 !important;
    border: 1px solid #ececec !important;
    pointer-events: none !important;
    img {
      filter: none;
    }
    span {
      color: #a6a8a7;
    }
  }

  &:focus {
    border-color: #2d6a4a !important;
    color: #fff !important;
    background-color: #2d6a4a !important;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(56%)
        saturate(428%) hue-rotate(69deg) brightness(92%) contrast(87%);
    }
  }

  &:hover {
    border-color: #7fb89a !important;
    color: #fff !important;
    background-color: #7fb89a !important;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(56%)
        saturate(428%) hue-rotate(69deg) brightness(92%) contrast(87%);
    }

    svg,
    svg path {
      fill: var(--bs-primary);
    }
  }
}

.common_cancel_btn {
  @extend .cursor-pointer;
  @extend .common_btn;
  color: #202020 !important;
  background-color: #fff !important;
  border: 1px solid #dcdbdd !important;

  &:focus-visible {
    outline: 0 !important;
    border-color: var(--bs-primary) !important;
    color: var(--bs-primary) !important;
  }
  &:focus {
    border-color: #dcdbdd !important;
    color: #202020 !important;
    background-color: #fff !important;
  }
}

.not-allowed {
  opacity: 0.8 !important;
  background-color: #fff !important;
  color: #202020 !important;
  border: 1px solid #dcdbdd !important;
  pointer-events: none !important;

  svg,
  svg path {
    fill: #202020;
  }
}
.tooltip-hide {
  @media screen and (max-width: 576px) {
    display: none;
  }
}
.tooltip-cust {
  .ant-tooltip-inner {
    border-radius: 4px;
  }

  &.tooltip-header {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        --antd-arrow-background-color: linear-gradient(
          to right bottom,
          #edeeea,
          #edeeea
        );
      }
    }

    .ant-tooltip-inner {
      // border-radius: 8px;
      background: #edeeea;
      font-size: 12px;
      line-height: 18px;
      color: #191c1a;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      box-shadow:
        0px 2px 6px 0px rgba(0, 0, 0, 0.15),
        0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    }
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px !important;
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:active {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #d2cfd1;
  }
}

.copy-btn {
  border-color: rgba(var(--bs-primary-rgb), 0.5) !important;
  border-width: 2px !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #c6dfd2;
}

.anticon-plus svg {
  fill: var(--bs-primary);
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: -48px;
}

@media screen and (max-width: 576px) {
  .ant-modal-body {
    padding: 10px;
    width: 100%;
  }

  .ql-editor {
    padding-top: 45px;
  }
}

@media screen and (min-width: 992px) {
  .folder-img {
    padding-left: 32px;
  }
}

.ant-table-pagination-right {
  justify-content: center !important;
}

.ant-select:not(.ant-select-disabled) {
  &:hover {
    color: #000 !important;

    .ant-select-selector {
      border-color: #d9d9d9;
      border: 1px solid #d9d9d9;
    }

    .ant-select-selection-placeholder {
      color: #000;
    }

    svg {
      fill: #409568;
    }
  }
}

.ant-select:not(.ant-select-disabled) {
  .ant-select-selector {
    border-color: #d9d9d9;
    border: 1px solid #d9d9d9;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  ) {
  .ant-select-selector {
    border-color: #d9d9d9;
    box-shadow: none;
    border-width: 1px;
    outline: 0;
  }

  .ant-select-selection-placeholder {
    color: #000;
  }

  svg {
    fill: #409568;
  }
}

.ant-select {
  color: #a6a8a7 !important;
}

.ant-select-selection-placeholder {
  color: #a6a8a7;
}

.ant-dropdown-link:hover {
  .drop-title {
    color: #11291c !important;
  }

  svg {
    fill: #11291c;
  }
}

.ant-dropdown-link:focus {
  .drop-title {
    color: #11291c !important;
  }

  svg {
    fill: #11291c;
  }
}

.custom-label {
  input {
    color: #323232;

    &:active {
      color: #11291c;
    }

    &:focus {
      color: #11291c;
    }
  }

  &:hover {
    input {
      color: #11291c;
    }

    input::placeholder {
      color: #11291c;
    }
  }
}

.tooltip-model {
  max-width: 580px;
  width: 100%;
  height: auto;

  .tooltip-content {
    max-height: 304px;
    overflow-y: scroll;
  }

  .tooltip-model-btn {
    color: #409568;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    column-gap: 4px;
    border: none;
    background: transparent;
    outline: none;
  }

  .ant-popover-inner {
    border-radius: 16px;
    background: #fff;
    border: 1px solid #c6dfd2;
    padding: 16px;
  }

  .list-menu-info {
    font-size: 14px;
    line-height: 20px;

    span {
      color: #409568;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }

    ul {
      list-style-type: decimal;
    }
  }
}

.tooltip-model .ant-popover-inner-content {
  width: 100%;
  background: #ffffff;
  border-radius: 15px;
  position: static;
  right: 0;
  top: 0px;
  padding: unset;
}

.ant-layout-content {
  // z-index: 99;
}

.ant-layout-header {
  @media screen and (max-width: 991px) {
    line-height: 0;
  }
}
nz-tab-nav-operation {
  @media screen and (max-width: 576px) {
    overflow: visible;
  }
}
.ant-form-item {
  @media screen and (max-width: 575px) {
    margin-bottom: 12px;
  }
}
.ant-modal {
  @media screen and (max-width: 575px) {
    max-width: calc(75vw - 16px);
    margin: 8px auto;
  }
}
.ant-avatar-string {
  @media screen and (max-width: 991px) {
    transform: scale(1) translateX(-50%) !important;
  }
}

.tools-option {
  .passage-wrapper {
    padding-top: 80px;
  }
}
.interlinear .ant-table-thead tr th:nth-child(3) {
  min-width: 115px;
  width: 115px;
}
@media screen and (max-width: 575px) {
  .ant-table-thead tr th:first-child .ant-table-column-title {
    padding-left: 14px !important;
  }
  .ant-table-tbody tr td:first-child > div {
    padding-left: 14px !important;
  }
  .ant-table-thead tr th:first-child {
    min-width: 145px !important;
    width: 145px !important;
  }
  .ant-table-thead tr th:nth-child(3) {
    min-width: 100px;
    width: 100px;
  }
  .interlinear .ant-table-thead tr th:first-child {
    min-width: 100px !important;
    width: 100px !important;
  }
  .interlinear .ant-table-thead tr th:nth-child(3) {
    min-width: 70px;
    width: 70px;
  }
  .interlinear tr td:nth-child(3) {
    text-align: center;
  }
}
.custom-class-1 {
  button {
    background: transparent;
    border: none;
    color: transparent;
  }
  .ant-popover-arrow {
    display: none;
  }
}
.custom-class-1 .ant-popover-inner-content {
  width: 580px;
  max-height: 386px;
  overflow-y: auto;
  border-radius: 16px;
  border: 1px solid #c6dfd2;
  background: #fff;
  box-shadow: 7px 2px 34px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: -635px;
  top: -150px;
  padding: 16px;
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px !important;
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:active {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #d2cfd1;
  }
  @media screen and (max-width: 1200px) {
    width: 420px;
    right: -472px;
  }
  @media screen and (max-width: 1023px) {
    right: 16px;
  }
  @media screen and (max-width: 500px) {
    width: 320px;
  }
  @media screen and (max-width: 375px) {
    right: -32px;
    top: -150px;
  }
}
.ant-layout-sider-zero-width > * {
  overflow: visible;
}
.model_btn {
  .common_cancel_btn {
    color: #202020 !important;
    background-color: #fff !important;
    border: 1px solid #dcdbdd !important;
    &:hover {
      border-color: #7fb89a !important;
      color: #fff !important;
      background-color: #7fb89a !important;
    }
  }
}

//   a.nb-verse {
//     display: block;
// }

// section {
//     display: grid;
//     grid-template-columns: 30% 70%;
// }

// p {
//     line-height: 35px;
// }

// nav {
//     top: 0;
//     position: sticky;
//     min-height: 100vh;
//     max-height: 100vh;
//     overflow-x: auto;
// }

.hover-container {
  display: inline-block;
  color: #409568;
}

.to-show-hover-content-container {
  // display: inline-block;
  // position: absolute;
  // // background-color: #fff;
  // background-color: #409568;
  // color: #000;
  // padding: 10px;
  // font-size: 13.4px;
  // border-radius: 3px;
  // border: 1px solid #ecf4f0;
  // white-space: pre-line;
  // right: 25px;
  // z-index: 9;
  // margin-top: 22px;
  // font-weight: 500;
  // width: calc(100% - 50px);
  // border-radius: 8px;
  // background: #ecf4f0;
  // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  // background-color: #fff;
  background-color: #409568;
  color: #000;
  padding: 10px;
  font-size: 13.4px;
  border-radius: 3px;
  border: 1px solid #ecf4f0;
  white-space: pre-line;
  right: 25px;
  z-index: 9;
  // margin-top: 5px;
  font-weight: 500;
  width: calc(100% - 50px);
  border-radius: 8px;
  background: #ecf4f0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  .icon-button {
    order: -1;
    display: flex;
    justify-content: flex-end;
    border: none;
    background: transparent;
    padding: 0;
  }
}

.hidden {
  display: none;
}

.hover-container-s-container {
  color: #409568;
  border-bottom: 1px solid #409568;
}
.book-layout .ant-layout-sider-zero-width-trigger {
  top: 0;
}
.card-body-padding .ant-card-body {
  padding: 16px;
}
.book-layout .ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.book-layout .ant-layout {
  // background: #ECF4F0;
  background: white;
  // overflow-y: scroll;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px !important;
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-track:active {
    background-color: #dfe9eb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #d2cfd1;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #d2cfd1;
  }
}
.bottomPassageData {
  text-align: end;
}
.sub-overlay {
  .file-popover,
  .copy-popover,
  .hide-popover {
    border: none;
    background: transparent;
    padding: 0;
  }
  .ant-popover-inner-content {
    width: 500px;
    background: #ffffff;
    border-radius: 15px;
    position: absolute;
    left: 0;
    bottom: 0;
    top: unset;
    right: unset;
    padding: 10px;
    box-shadow: 7px 2px 34px 0px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 850px) {
      right: 0;
      left: unset;
      width: 380px;
    }
    @media screen and (max-width: 820px) {
      width: 380px;
    }
    @media screen and (max-width: 767px) {
      right: unset;
      left: 0;
    }
    @media screen and (max-width: 682px) {
      right: unset;
      left: -110px;
    }
    @media screen and (max-width: 576px) {
      width: 300px;
    }
    @media screen and (max-width: 471px) {
      left: -176px;
    }
  }
}
.book-wrapper {
  .ant-tabs-tab {
    border: 1px solid #c6dfd2 !important;
    border-bottom: 1px solid #fff !important;
  }
}
.library-container {
  overflow: hidden !important;
  .ant-tabs-nav {
    margin-left: 40px;
  }
}
.prompt-relative {
  .ql-container.ql-snow {
    border: none;
  }
  .ql-editor {
    padding: 0;
  }
}
.ql-container {
  font-family: "Poppins", sans-serif !important;
}
.cdk-overlay-pane {
  max-width: 100%;
}
.book-drop-wrapper {
  top: 10px;
  left: -10px;
  width: min-content;
  min-width: 269px;
  .multiple-dropdown {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    li {
      color: #a6a8a7;
      &:hover {
        background: #ecf4f0;
      }
      &.active-list {
        background: #c4ded0;
        color: #409568;
      }
    }
  }

  input {
    border-radius: 8px;
    border: 1px solid #a6a8a7;
    height: 30px;
    margin: 8px 0 10px 0;
  }
  h6 {
    font-size: 8px;
    color: #a7ceba;
  }
  .ant-divider {
    border-top: 1px solid #a7ceba;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #656766;
    margin-bottom: 9px;
    margin-top: 8px;
  }
  .dropdown--list {
    margin: 8px 0 0 0;
    li {
      margin-bottom: 8px;
    }
  }
}
.tools-title {
  li {
    padding-bottom: 8px;
  }
}
.topic-drop {
}
.mh-100 > div > .ant-spin {
  max-height: 100% !important;
}
.cdk-overlay-container {
  z-index: 99999;
}

.book-layout {
  .ant-menu-inline.ant-menu-root .ant-menu-item {
    display: block;
    margin-bottom: 26px;
    height: auto;
    margin-top: 4px;
    padding: 0 8px 0 16px;
    overflow: visible;
    line-height: 18px;
    text-overflow: unset;
    white-space: normal;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    height: 100%;
    min-height: 100%;
  }
}
.book-list-main.ant-spin-nested-loading > div > .ant-spin {
  height: 100%;
  min-height: 100%;
}
.model-tutorial .ant-modal-content {
  display: block;
}
nz-select {
  nz-select-arrow {
    .anticon-down {
      width: 8px;
      height: 5px;
      background-size: cover;
      background-position: center;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.25 0.5L4 4.25L7.75 0.5H0.25Z' fill='%23A6A8A7'/%3E%3C/svg%3E%0A");
      svg {
        display: none;
      }
    }
  }
}
.ant-dropdown-trigger {
  .anticon-down {
    width: 8px;
    height: 5px;
    background-size: cover;
    background-position: center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.25 0.5L4 4.25L7.75 0.5H0.25Z' fill='%23A6A8A7'/%3E%3C/svg%3E%0A");
    svg {
      display: none;
    }
  }
}
.ibrary-sidebar-button {
  .anticon-menu {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2H14V0H0V2ZM0 6H14V4H0V6ZM0 10H14V8H0V10ZM16 10H18V8H16V10ZM16 0V2H18V0H16ZM16 6H18V4H16V6Z' fill='%23656766'/%3E%3C/svg%3E%0A");
    // width: 18px;
    // height: 10px;
    width: 24px;
    height: 15px;
    background-size: cover;
    background-position: center;
    svg {
      display: none;
    }
  }
}
nz-tabset,
nz-tab-nav-operation,
nz-tabs-nav {
  overflow: visible;
}
.book-layout {
  .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .ant-menu-inline .ant-menu-inline .ant-menu-submenu-title {
    padding-left: 10px !important;
  }
  .ant-menu-item {
    padding-left: 15px !important;
  }
}

.outline-collapse-panel {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    display: none;
  }
  .ant-collapse-arrow {
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM16.5 16.5H1.5V1.5H16.5V16.5ZM13.5 9C13.5 9.19891 13.421 9.38968 13.2803 9.53033C13.1397 9.67098 12.9489 9.75 12.75 9.75H9.75V12.75C9.75 12.9489 9.67098 13.1397 9.53033 13.2803C9.38968 13.421 9.19891 13.5 9 13.5C8.80109 13.5 8.61032 13.421 8.46967 13.2803C8.32902 13.1397 8.25 12.9489 8.25 12.75V9.75H5.25C5.05109 9.75 4.86032 9.67098 4.71967 9.53033C4.57902 9.38968 4.5 9.19891 4.5 9C4.5 8.80109 4.57902 8.61032 4.71967 8.46967C4.86032 8.32902 5.05109 8.25 5.25 8.25H8.25V5.25C8.25 5.05109 8.32902 4.86032 8.46967 4.71967C8.61032 4.57902 8.80109 4.5 9 4.5C9.19891 4.5 9.38968 4.57902 9.53033 4.71967C9.67098 4.86032 9.75 5.05109 9.75 5.25V8.25H12.75C12.9489 8.25 13.1397 8.32902 13.2803 8.46967C13.421 8.61032 13.5 8.80109 13.5 9Z' fill='%23235239'/%3E%3C/svg%3E%0A");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    right: 0 !important;
  }
  .ant-collapse-item-active {
    .ant-collapse-arrow {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM16.5 16.5H1.5V1.5H16.5V16.5ZM13.5 9C13.5 9.19891 13.421 9.38968 13.2803 9.53033C13.1397 9.67098 12.9489 9.75 12.75 9.75H5.25C5.05109 9.75 4.86032 9.67098 4.71967 9.53033C4.57902 9.38968 4.5 9.19891 4.5 9C4.5 8.80109 4.57902 8.61032 4.71967 8.46967C4.86032 8.32902 5.05109 8.25 5.25 8.25H12.75C12.9489 8.25 13.1397 8.32902 13.2803 8.46967C13.421 8.61032 13.5 8.80109 13.5 9Z' fill='%23235239'/%3E%3C/svg%3E%0A");
    }
  }
  .ant-collapse-item {
    padding: 16px;
    position: relative;
  }
  .ant-collapse > .ant-collapse-item {
    padding-left: 16px !important;
  }
  .ant-collapse-item > div:first-child {
    color: #3e4140;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    margin-left: 0;
  }
  .devider {
    border-bottom: 0.5px solid #2d6a4a;
    padding-bottom: 15px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    border-left: none;
  }
  .content {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
.booklistpopover .ant-popover-inner-content {
  width: 220px;
  background: #ffffff;
  border-radius: 8px;
  position: static;
  right: unset;
  top: unset;
  padding: 8px 12px;
}
.booklistpopover .ant-popover-inner {
  border-radius: 8px;
}
.infocircle {
  padding-left: 16px;
  cursor: pointer;
  &:hover {
    svg {
      fill: #409568;
    }
  }
}
.summarized-popover {
  .ant-popover-inner {
    background-color: transparent;
    background-clip: padding-box;
    border-radius: 0px;
    box-shadow: none;
  }
  .ant-popover-inner-content {
    background: #ecf4f0;
    border: 1px solid #c4ded0;
    border-radius: 8px;
    position: static;
    right: unset;
    top: unset;
    padding: 8px 12px;
    max-width: 580px;
    min-width: 300px;
    width: 90%;
    @media screen and (max-width: 576px) {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 75%;
    }
  }
}
.chat-popover {
  width: 390px;
  .ant-popover-inner-content {
    width: 380px;
    border: 1px solid #a7ceba;
    background: #ecf4f0;
    padding: 8px;
    box-shadow: 0 0px 16px 0px rgba(0, 0, 0, 0.08);
  }
  .ant-popover-inner {
    background: transparent;
    box-shadow: none;
  }
  h6 {
    color: #000000;
    font-size: 10px;
    line-height: 9.6px;
  }
  p {
    font-size: 10px;
    color: #11291c;
    line-height: 15px;
    margin: 0;
  }
}
.chat-main-container {
  .ant-card {
    border-radius: 9px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid #c4ded0;
    }
  }
  .ant-avatar-lg {
    width: 41px;
    height: 41px;
    line-height: 41px;
  }
  .ant-avatar-square {
    border-radius: 4px;
  }
  .anticon-plus svg {
    fill: #ffffff;
  }
  .ant-input-affix-wrapper {
    padding: 0;
    line-height: normal;
  }
  .bottom-div {
    .ant-input-affix-wrapper {
      border-radius: 4px;
    }
    input {
      height: 40px;
      padding: 12px 16px;
      border-radius: 4px;
    }
  }
}
.info-btn {
  cursor: pointer;
  &:hover {
    color: #409568 !important;
  }
  svg {
    &:hover {
      fill: #409568 !important;
    }
  }
}
.mob-bottom-sec {
  @media only screen and (max-width: 576px) {
    // position: fixed;
    // bottom: 20px;
    // left: 50%;
    // transform: translateX(-50%);
  }
}
p.upgrade_year a span {
  text-decoration: underline;
}
.pricing-tab {
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0px solid #c6dfd2;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    background: transparent;
    border: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    background: #f6f6f6;
    border-radius: 14px;
    padding: 8px;
  }
  .ant-tabs-tab-btn {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 12px 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    background: #3f9568;
    left: 5px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img {
    position: relative;
    filter: brightness(0) invert(1);
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 0;
  }
  .ant-tabs-content-holder {
    margin-top: 40px;
  }
}
.pricing-table {
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    text-align: center;
    border-color: #a7ceba;
    border-bottom: none;
    &:first-child {
      font-size: 20px;
      text-align: left;
    }
    &:last-child {
      border-right: none;
    }
    span {
      color: #979897;
    }
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    text-align: center;
    border-color: #a7ceba;
    border-bottom: none;
    &:first-child {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
    }
    &:last-child {
      border-right: none;
    }
  }
  .ant-table-thead > tr > th {
    background: #ecf4f0;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr {
    &:nth-child(even) {
      td {
        background: #ecf4f0;
      }
    }
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 12px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 12px;
  }
  table {
    border: none;
  }
  .ant-table-wrapper {
    overflow-x: scroll;
  }
}
.activeTranslation {
  background-color: #ececec !important;
  border: 1px solid #409568;
  border-radius: 12px;
}
.pricing-popover {
  max-width: 380px;
  .ant-popover-inner {
    border: 1px solid #a7ceba;
    border-radius: 4px;
    background: #ecf4f0;
  }
  .ant-popover-inner-content {
    padding: 4px 8px;
  }
}
.bible-translation-model {
  h3 {
    font-size: 32px;
    margin-bottom: 16px;
    color: #00030d;
    @media screen and (max-width: 600px) {
      font-size: 20px;
      margin-top: 48px;
    }
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #00030d;
    margin-bottom: 26px;
  }
  .ant-modal {
    max-width: 790px;
    width: calc(100% - 30px) !important;
    @media screen and (max-width: 600px) {
      width: 100% !important;
      height: 90%;
      background: #fff;
      margin: 0;
      bottom: -5%;
      top: unset;
      border-radius: 24px 24px 0 0;
    }
  }
  .bible-translation-item {
    background: #fafafa;
    padding: 10px;
    border-radius: 8px;
    margin: 6px auto;
    font-size: 16px;
    color: #00030d;
    max-width: 317px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    strong {
      font-size: 14px;
      padding-right: 10px;
    }
    &:hover {
      background: #ececec !important;
    }
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    @media screen and (max-width: 600px) {
      border-radius: 24px 24px 0 0;
      box-shadow: none;
      height: 100%;
    }
  }
  .ant-modal-body {
    @media screen and (max-width: 600px) {
      height: 100%;
      padding: 40px;
    }
  }
}
.prompt-step-3.compare-translation .ant-collapse-content {
  background-color: transparent;
}
.prompt-step-3.compare-translation
  .ant-collapse-item-active
  .ant-collapse-header {
  margin-bottom: 20px;
}
.to-show-hover-content-container.compare-translation-hover-content {
  background: #fff;
  border: 1px solid #c4ded0;
  border-radius: 8px;
  .top-heading {
    color: #c4ded0e5;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .sub-text {
    color: #192c20;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  .sub-text-top {
    color: #a6a8a7;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .devider {
    border-bottom: 1px solid #c4ded0;
    margin-bottom: 14px;
  }
}
.max-w-100 {
  max-width: 100% !important;
}
.disabled-btn {
  .ant-select-selector {
    border-color: #ececec;
    background: #ececec !important;
    font-size: 14px;
  }
  .placeholder-label {
    top: 8px !important;
    left: 10px !important;
    background: #ececec !important;
    width: 60px;
    color: #a6a8a7 !important;
  }
}
.discission-questions {
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)
    img {
    filter: brightness(0) invert(1);
  }
}
nz-select-top-control {
  border-radius: 8px !important;
}
.mobile-custom-model.custom-dropdown-for {
  background-color: transparent;
  padding: 0;
  border-radius: 0px;
  border: none;
}
.mobile-custom-model {
  @media screen and (max-width: 575px) {
    .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto;
    }
  }
  .ant-modal-content {
    border-radius: 8px;
    display: block;
  }
  .ant-menu-vertical .ant-menu-item {
    height: auto;
    line-height: normal;
  }
  ul.ant-menu.ant-menu-root.ant-menu-light.ant-menu-vertical {
    border: none;
    border-radius: 0;
  }
  .topic-prompt .data-button {
    white-space: normal;
  }
  .ant-menu-item img {
    margin-right: 0;
  }
}
.export-modal-title {
  color: #474747;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 18px;
}
.down-icon-export {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 9999;
  transform: translateY(-50%);
}
.verse {
  color: #a6a8a7;
}

.verse.highlight {
  color: #3e4140;
}

.word-study-data {
  background: #fafafa;
  border-radius: 8px;
  padding: 20px 22px;
  position: relative;
  margin-bottom: 8px;
  h3 {
    font-size: 13px;
    line-height: 28px;
    font-weight: 700;
    color: #000;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #000;
  }
  .word-study-cta {
    position: absolute;
    top: 20px;
    right: 22px;
  }
  .to-show-hover-content-container {
    background: #fff;
    border: 1px solid #c4ded0;
  }
  .top-heading {
    color: #c4ded0e5;
    font-size: 16px;
    line-height: 19px;
  }
  .sub-text {
    font-size: 14px;
    line-height: 20px;
    color: #192c20;
  }
  .sub-text-top {
    color: #a6a8a7;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #c4ded0;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #192c20;
  }
}

.meni_icon {
  img {
    transition: 0.3s all ease;
    &:hover {
      border: 3px solid #c4ded0e5;
      border-radius: 50%;
    }
  }
}
.header-drop-li {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  &:hover {
    background: #c4ded0e5 !important;
    color: #409568 !important;
  }
}
.header-drop-li {
  &:hover {
    background: #c4ded0e5 !important;
    color: #409568 !important;
  }
}
.header-drop-li:hover i {
  color: #409568 !important;
}
.header-drop-li:hover img {
  filter: invert(44%) sepia(98%) saturate(245%) hue-rotate(96deg)
    brightness(95%) contrast(87%);
}
.interlinear {
  position: relative;
  .ant-table-thead > tr > th {
    background: transparent;
    color: #192c20e5;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    padding: 0 0 5px 0;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-right: none;
  }
  .ant-table-thead > tr > th:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    width: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px solid #f0f0f0;
    color: #192c20;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-top: none;
    border-right: none;
  }
  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.06);
  }
  .ant-table-tbody > tr:nth-child(odd) > td {
    background: #c6dfd24d;
  }
  .hover-container-greek-hebrew {
    color: #409568;
  }
  li {
    list-style-type: none;
  }
  .page-index {
    a {
      color: #c4ded0;
      font-weight: 600;
      text-decoration: none;
      width: 31px;
      height: 32px;
      border: 1px solid #c4ded0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
    a.active {
      background: #c4ded0;
      color: #fff;
    }
  }
  .pagination-row {
    column-gap: 8px;
    justify-content: end;
  }
  .interlinear-content {
    width: 100%;
    flex-direction: column;
    align-items: flex-start !important;
    position: relative;
    padding-bottom: 42px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
    .pagination-position {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .list-item {
      width: 60%;
    }
  }
  .compare-translation-top-btn {
    text-align: end;
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
  }
  .to-show-hover-content-container {
    background: #fff;
    border: 1px solid #c4ded0;
  }
  .top-heading {
    color: #c4ded0e5;
    font-size: 16px;
    line-height: 19px;
  }
  .sub-text {
    font-size: 14px;
    line-height: 20px;
    color: #192c20;
  }
  .sub-text-top {
    color: #a6a8a7;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #c4ded0;
    padding-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: #192c20;
  }
}
.ant-tag {
  border-radius: 8px;
}
.promp-body-pasaage {
  .bible-body-pasaage-top {
    background: #fff;
    padding: 16px;
    margin-right: -16px;
    margin-left: -16px;
    position: absolute;
    width: calc(100% + 24px);
    top: 82px;
    z-index: 999999;
  }
  .page-index {
    a {
      color: #c4ded0;
      font-weight: 600;
      text-decoration: none;
      width: 31px;
      height: 32px;
      border: 1px solid #c4ded0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
    a.active {
      background: #c4ded0;
      color: #fff;
    }
  }
  .pagination-row {
    column-gap: 8px;
    justify-content: end;
  }
}

.literaturepopover {
  .ant-popover-inner-content {
    right: unset;
    top: unset;
    background: #ffffff;
    border-radius: 8px;
    position: static;
    padding: 10px;
  }
}
.input-container {
  input {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
  a {
    color: #409568;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
@media screen and (max-width: 576px) {
  .cdk-overlay-pane:has(.book-drop-wrapper) {
    top: 15% !important;
    left: 50% !important;
    transform: translate(-50%, 0%);
    position: absolute !important;
  }
  .cdk-overlay-connected-position-bounding-box:has(.book-drop-wrapper) {
    background: #00000052;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
}

.book-dropdown-wrapper {
  &:hover {
    .ant-select:not(.ant-select-disabled) .ant-select-selector {
      border-color: #3f9568;
      border: 1px solid #3f9568;
    }
    .placeholder-label {
      color: #000000 !important;
    }
    .ant-select:not(.ant-select-disabled),
    .ant-select-selection-placeholder {
      color: #000 !important;
    }
  }
}
.book-dropdown-wrapper {
  .ant-select-focused {
    .ant-select-selector {
      border-color: #5fa17b !important;
    }
  }
}
.book-dropdown-wrapper {
  .ant-select-focused ~ .placeholder-label {
    color: #000000 !important;
  }
}

.ant-select-focused {
  .ant-select-selector {
    border-color: #5fa17b !important;
  }
  .ant-select-selection-item {
    color: #000000 !important;
  }
}
.ant-select-focused ~ .placeholder-label {
  color: #000000 !important;
}
.ant-select:not(.ant-select-disabled) .ant-select-selector:hover {
  border-color: #3f9568;
  border: 1px solid #3f9568;
}
.ant-select:hover ~ .placeholder-label {
  color: #000000 !important;
}
nz-input-group {
  &:hover {
    input {
      border: 1px solid #3f9f68 !important;
    }
    label {
      color: #000;
    }
  }
}
nz-input-group.cdk-focused {
  input {
    border: 1px solid #3f9f68 !important;
  }
  label {
    color: #000;
  }
}
.tooltip-translation {
  .ant-tooltip-inner {
    background: #fff;
    color: #000;
    font-size: 12px;
    line-height: 14.5px;
  }
  .ant-tooltip-arrow-content::before {
    background: #fff;
  }
}
.translation-aerrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a6a8a7;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  left: 9%;
  top: 0;
  position: absolute;
  @media screen and (max-width: 700px) {
    left: 3%;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.compare-translation .ant-collapse-item-active {
  .ant-collapse-arrow {
    display: none !important;
  }
  // pointer-events: none;
}
.fs-12 {
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
.me-12 {
  margin-right: 12px;
}
.color-grey {
  color: #a6a8a7;
}
.color-grey-2 {
  color: #ddd9d9;
}
.color-gray-1 {
  color: #656766;
}
.color-grey-3 {
  color: #667080;
}
.color-grey-4 {
  color: #767776;
}
.b-radious-32 {
  border-radius: 32px;
}
.color-green {
  color: #409568;
}
.switch-bible {
  background-color: #fafafa;
  border-radius: 9px 9px 0 0;
  border-bottom: 0.5px solid #409568;
  padding: 8px 3px 0 3px;
  margin-bottom: 12px;
}
.collepase-btn {
  left: 200px;
  border-radius: 0 16px 16px 0;
  background: #c4ded0;
}
.beta-tag {
  background-color: rgb(64, 149, 104);
  color: #fff;
  width: fit-content;
}
.w-27 {
  width: 27px;
}
.mt-80 {
  margin-top: 80px;
}
.searchinput {
  font-size: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pr-10 {
  padding-right: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.hover-container-greek-hebrew {
  color: #409568;
}
.bg-fafafa {
  background-color: #fafafa;
}
.answer-content {
  background-color: #ecf4f0;
  padding: 16px 10px;
  margin: 16px 0;
}
.fs-10 {
  font-size: 10px;
}

.hover-container-greek-hebrew {
  color: #409568;
}
.stepper-video{
  border: 1px solid rgba(64, 149, 104, 1);
  border-radius: 16px;
  overflow: hidden;
  @media screen and (max-width:1440px) {
    width: 60% !important;
    margin: auto;
  }
}
.step-indication{
  text-align: center;
  font-weight: 700;
  font-size: 8px !important;
  line-height: 10px !important;
  margin-bottom: 6px !important;
}
.stepper-line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
}
.stepper-line .line{
  height: 5px;
  background: rgba(209, 209, 209, 1);
  border-radius: 100px;
  width: calc(50% - 4px);
}
.stepper-line .line.completed{
  background: rgba(167, 206, 186, 1);
}
.btn-second{
  color: rgba(64, 149, 104, 1);
  background-color: #fff;
  border: 1px solid rgba(64, 149, 104, 1);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  transition: all 0.3s;
  &:hover{
    color: #fff;
    background: rgba(64, 149, 104, 1);
  }
}
.stepper-btn-row{
  display: flex;
  align-items: center;
  column-gap: 24px;
  padding-top: 21px;
  justify-content: center;
}
.bible-translation-model .ant-modal-content{
  display: block;
}
.bible-translation-model .ant-modal-body{
  padding: 48px 40px;
}